exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-gaming-discord-tsx": () => import("./../../../src/pages/gaming/discord.tsx" /* webpackChunkName: "component---src-pages-gaming-discord-tsx" */),
  "component---src-pages-gaming-index-tsx": () => import("./../../../src/pages/gaming/index.tsx" /* webpackChunkName: "component---src-pages-gaming-index-tsx" */),
  "component---src-pages-gaming-minecraft-tsx": () => import("./../../../src/pages/gaming/minecraft.tsx" /* webpackChunkName: "component---src-pages-gaming-minecraft-tsx" */),
  "component---src-pages-gaming-palworld-tsx": () => import("./../../../src/pages/gaming/palworld.tsx" /* webpackChunkName: "component---src-pages-gaming-palworld-tsx" */),
  "component---src-pages-gaming-support-the-project-awww-tsx": () => import("./../../../src/pages/gaming/support-the-project/awww.tsx" /* webpackChunkName: "component---src-pages-gaming-support-the-project-awww-tsx" */),
  "component---src-pages-gaming-support-the-project-index-tsx": () => import("./../../../src/pages/gaming/support-the-project/index.tsx" /* webpackChunkName: "component---src-pages-gaming-support-the-project-index-tsx" */),
  "component---src-pages-gaming-support-the-project-thanks-tsx": () => import("./../../../src/pages/gaming/support-the-project/thanks.tsx" /* webpackChunkName: "component---src-pages-gaming-support-the-project-thanks-tsx" */),
  "component---src-pages-gaming-valheim-tsx": () => import("./../../../src/pages/gaming/valheim.tsx" /* webpackChunkName: "component---src-pages-gaming-valheim-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-locations-adelaide-tsx": () => import("./../../../src/pages/locations/adelaide.tsx" /* webpackChunkName: "component---src-pages-locations-adelaide-tsx" */),
  "component---src-pages-locations-index-tsx": () => import("./../../../src/pages/locations/index.tsx" /* webpackChunkName: "component---src-pages-locations-index-tsx" */),
  "component---src-pages-locations-melbourne-tsx": () => import("./../../../src/pages/locations/melbourne.tsx" /* webpackChunkName: "component---src-pages-locations-melbourne-tsx" */),
  "component---src-pages-services-cloud-consulting-tsx": () => import("./../../../src/pages/services/cloud-consulting.tsx" /* webpackChunkName: "component---src-pages-services-cloud-consulting-tsx" */),
  "component---src-pages-services-index-tsx": () => import("./../../../src/pages/services/index.tsx" /* webpackChunkName: "component---src-pages-services-index-tsx" */),
  "component---src-pages-services-video-production-tsx": () => import("./../../../src/pages/services/video-production.tsx" /* webpackChunkName: "component---src-pages-services-video-production-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */)
}

