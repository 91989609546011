/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "./src/components/layout.css";
require("prismjs/themes/prism-tomorrow.css");

import React from "react";

import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

export const wrapPageElement = ({ element }) => {
  let cache = createCache({ key: `css` });
  return <CacheProvider value={cache}>{element}</CacheProvider>;
};
